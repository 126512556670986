/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// LABEL - START

/* GENERAL - START */

form
	div
		h3
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		fieldset
			legend
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			label
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			label.LabelPreffix,	label.LabelSuffix
				font-family: $URL_FAMILYPRIMARY_SECONDARY

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2, h3
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		p
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	div.DivForm
		h3, h4
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		label, .legend-title, mat-label
			font-family: $URL_FAMILYPRIMARY_PRIMARY
		span, p
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea#spanIncidentNote
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea:disabled
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		textarea.TextareaDescriptionHeader, textarea.TextareaConversationDisable
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label, h2
					font-family: $URL_FAMILYPRIMARY_SECONDARY
			div.DivHeaderStatus
				span
					font-family: $URL_FAMILYPRIMARY_PRIMARY
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt
							font-family: $URL_FAMILYPRIMARY_PRIMARY
						dd
							font-family: $URL_FAMILYPRIMARY_SECONDARY
				div.DivHeaderList
					ul
						li
							span
								font-family: $URL_FAMILYPRIMARY_PRIMARY

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			span
				font-family: $URL_FAMILYPRIMARY_SECONDARY

div.DivRoundButtonContainer
	label
		font-family: $URL_FAMILYPRIMARY_SECONDARY !important

/* GENERAL - END */

/* NAVIGATION - START */

nav
	figure
		span#spanProfileNameInitial
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		figcaption
			span#spanProfileName
				font-family: $URL_FAMILYPRIMARY_SECONDARY
			span#spanProfilePosition
				font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul
		li
			font-family: $URL_FAMILYPRIMARY_PRIMARY

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		span
			font-family: $URL_FAMILYPRIMARY_PRIMARY
	form
		div.DivNotRegistered
			font-family: $URL_FAMILYPRIMARY_PRIMARY

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard
	div.DivHighlight
		span.SpanHighlight
			font-family: $URL_FAMILYPRIMARY_SECONDARY

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			font-family: $URL_FAMILYPRIMARY_SECONDARY

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivTableListContainer, div.DivTableListContainerLeft
	span.SpanTablePage
		font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul.ListTableThumbnails
		figure
			figcaption
				font-family: $URL_FAMILYPRIMARY_PRIMARY
	ul.ListTableTicket, ul.ListTableConversation
		span.SpanTicketNumber, span.SpanConversationDate
			font-family: $URL_FAMILYPRIMARY_PRIMARY
		span.SpanTicketTitle, span.SpanConversationTitle
			font-family: $URL_FAMILYPRIMARY_SECONDARY
		dl
			dt
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			dd
				font-family: $URL_FAMILYPRIMARY_SECONDARY
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-family: $URL_FAMILYSECONDARY_PRIMARY

/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	span#spanLoadingPercentage
		font-family: $URL_FAMILYPRIMARY_SECONDARY
	span
		font-family: $URL_FAMILYPRIMARY_PRIMARY

/* LOADING - END */

// LABEL - END


// INPUT - START

/* GENERAL - START */

form
	div
		fieldset
			input
				font-family: $URL_FAMILYPRIMARY_PRIMARY
			div.DivConversationHeader
				span.SpanConversationDate
					font-family: $URL_FAMILYPRIMARY_PRIMARY
				span.SpanConversationTitle
					font-family: $URL_FAMILYPRIMARY_SECONDARY

/* GENERAL - END */

// INPUT - END


/* THIRD PARTY */

// DROPDOWN MULTIPLE -START

div.DivForm
	div.DivFormMultipleDropdown,div.DivFormSingleDropdown, div.DivFormLongTextDropdown
		span, p
				font-family: $URL_FAMILYPRIMARY_PRIMARY

// DORPDOWN MULTIPLE -END

// MONITORING RENEWAL - START

div.DivCardPolicy
	span, h2
		font-family: $URL_FAMILYPRIMARY_SECONDARY

div.DivPolicyAttributes
	span
		font-family: $URL_FAMILYPRIMARY_PRIMARY

div.DivCardInformationPolicy
	div.DivPolicyInfo
		span
			font-family: $URL_FAMILYPRIMARY_SECONDARY

// MONITORING RENEWAl - END